(function (window, document, $) {
    'use strict';
    $('.menu-icon').click(function () {
        if ($(this).parents('body').hasClass('menu-toggle')) {
            $(this).parents('body').removeClass('menu-toggle');
        } else {
            $(this).parents('body').addClass('menu-toggle');
        }
    })


})(window, document, jQuery);
